import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Input, Button } from "react-daisyui"
import { useFaqStore, CategoryStore, CategoryStoreContext } from "../Store/FaqStore";
import { observer } from "mobx-react";
import { useAuthStore } from "../../Auth/store/Auth";

// const Filter = observer(() => {
//     const store = new CategoryStore({
//         isloading:true,
//       });
//     return (
//         <CategoryStoreContext.Provider value={store}>
//             <Screen />
//         </CategoryStoreContext.Provider>
//     )
// })

// export default Filter;

const Filter = observer(({emptyinput}) => {
    const [searchParams] = useSearchParams();
    const searchParamPage = searchParams.get("page");
    const {Token} = useAuthStore();
    const { 
        setquestion,
        NofilterwithPagination,
        setfiltertrue,
        getFaq,
        getstatus,
        getFilterFaq,
        question,
        status,
        setToken
      } = useFaqStore();

      const Reset=()=>{
        setquestion("")
        getstatus("")
        // loadTableData(1)
        setfiltertrue(false);


    }
    useEffect(() => {
        setToken(Token)
        getFaq()
    },[])

    useEffect(()=> {
        console.log("emptyinputemptyinput",emptyinput)
        setquestion("")
        getstatus("")
    },[emptyinput])
    return (
        <>

            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form onSubmit={(e) => {NofilterwithPagination(false);getFilterFaq(1);e.preventDefault() }}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label htmlFor="name" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Question</label>
                            <Input
                                name="name"
                                placeholder="Question"
                                onChange={(e)=> {setquestion(e.target.value)}}
                                value={question}
                                id="name"
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status</label>
                            <select
                                id="Status"
                                name="Status"
                                value={status}
                                onChange={(e)=> {getstatus(e.target.value)}}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" className="">All</option>
                                <option value="active">Active</option>
                                <option value="in-active">Inactive </option>
                            </select>
                        </div>
                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <button type="reset" onClick={() => {getFaq(); Reset()  }} className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineRefresh className="mr-1" />Reset</button>
                        <button type="submit" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"><MdOutlineSearch className="mr-1" />Search</button>
                    </div>
                </form>
            </div>

        </>
    )
})

export default Filter;
