import {createContext,useContext} from 'react'
import { makeAutoObservable, runInAction } from "mobx";
import {getMethodData,putMethod, patchMethodData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class OrderStore {
    Token = null;
    indexPage=1;
    filtertrue=false;
    nofilterwithpagination= false;
    activePage=1;
    Count=1;
    perPage="";
    filterVisibility=true;
    ProductType="";
    ProductName="";
    loadOrder= false;
    errloadOrder = "";
    saved= false;
    nolist=false;
    Orders=[];
    orderone={};
    productlist=[];
    Producttypelist=[];
    orderid="";
    fromdate="";
    todate="";
    customername="";
    customeremail="";
    status="";
    paymentmethod ="";
    previousValues = {
      order_number: "",
      customer: "",
      email: "" ,
      from_date: "",
      to_date: "",
      payment_status:"",
      product_type: "",
      product_name: "",
      payment_method: ""
    };
    

    constructor(isloading = false) {
        makeAutoObservable(this);
        this.Token = localStorage.getItem("Admintoken");
        if(isloading.isloading){
            this.getOrders(isloading.pagginationcount);
            return
        }
        if(isloading.editloading){
            this.getOrderone(isloading?.id)
        }
    }


    setorderid = (text) =>  {
        this.orderid = text;
    }
    setfromdate = (text) =>  {
        this.fromdate = text;
    }
    settodate = (text) =>  {
        this.todate = text;
    }
    setcustomername = (text) =>  {
        this.customername = text;
    }
    setcustomeremail = (text) =>  {
        this.customeremail = text;
    }
    setstatus = (text) =>  {
        this.status = text;
    }
    setpaymentmethod = (text) =>  {
      this.paymentmethod = text;
  }
    setActivePage = (text) =>{
        this.activePage = text;
      }
      setIndexPage=(e)=>{
        this.indexPage=e;
      }
      setCount=(e)=>{
        this.Count=e;
      }
      setPerPage=(e)=>{
        this.perPage=e;
      }
      setFilterVisibility=(e)=>{
        this.filterVisibility=e;
      }
      setProductType = (text) =>{
        this.ProductType = text;
      }
      setproductname = (text) =>{
        this.ProductName = text;
      }
      setfiltertrue=(e)=>{
        this.filtertrue = e;
      }
      NofilterwithPagination = (e) => {
        runInAction(() => {
            this.nofilterwithpagination = e
        })
      }
      setToken=(e)=>{
        this.Token=e;
      }
    // get order List
    getOrders = (page) => {
        runInAction(() => {
            this.loadOrder = true;
            this.errloadOrder = "";
            this.saved = false;
            this.Orders=[]
            this.indexPage = page;
            });
        getMethodData(`/admin/orders?page=${page}`,this.Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                this.loadOrder = false;
                this.errloadOrder = resp?.data?.message
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                this.loadOrder = false;
                this.errloadOrder = resp?.data?.message;
                // console.log(resp?.data?.data.length);
                if(resp?.data?.data?.data?.length === 0) {
                    this.nolist = true;
                }else{
                    this.nolist = false;
                }
                // console.log(resp?.data?.data?.data)
                this.Orders = resp?.data?.data?.data;
                this.Count = resp?.data?.data?.total;
                this.perPage = resp?.data?.data?.per_page;
                this.activePage = resp?.data?.data?.current_page;
                this.filterVisibility = true;
                this.saved = false;
                // this.status = true;
                });
                return;
            }
            runInAction(() => {
                this.loadOrder = false;
                this.errloadOrder = resp?.data?.message;
            });
            })
            .catch((error) => {
            runInAction(() => {
                this.loadOrder = false;
                if(!error.response){
                    this.errloadOrder = error?.message;
                    toast.error(error?.message);
                }
                this.errloadOrder = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };
    loadTableData = async (pageNumber) => {
        runInAction(() => {
          this.loadOrder = true;
          this.errloadOrder = "";
          this.saved = false;
          this.setIndexPage(1);
  
          });
  
    
        try {
          const response = await getMethodData(`/admin/orders?page=${pageNumber === 1 ? 1 : pageNumber}`,this.Token)
          if (response?.data?.status === false) {
            runInAction(() => {
            this.loadOrder = false;
            this.errloadCategory = response?.data?.message
            });
            return;
        }
        if (response?.data?.status === true) {
          runInAction(() => {
            this.loadOrder = false;
            this.errloadOrder = response?.data?.message;
            // console.log(resp?.data?.data.length);
            if(response?.data?.data?.data?.length === 0) {
                this.nolist = true;
            }else{
                this.nolist = false;
            }
              this.setCount(response.data.data.total);
              this.setPerPage(response.data.data.per_page);
              this.setActivePage(response.data.data.current_page)
              this.Orders = response?.data?.data?.data;
              this.filterVisibility = true;
  });
        }
  
      
    
        } catch (error) {
          runInAction(() => {
            this.loadOrder = false;
            console.error("Error loading table data:", error);
        });
        }
      };
  // get product type list
  getProducttypeList = () => {
    runInAction(() => {
      this.loadOrder = true;
      this.errloadOrder = "";
    });
    getMethodData(`/admin/product-types`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadOrder = false;
            this.errloadOrder = "";
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadOrder = false;
            this.errloadOrder = resp?.data?.message;
          
            this.Producttypelist = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadOrder = false;
          this.errloadOrder = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadOrder = false;
          if (!error.response) {
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

    //get order edit data (show)

        getOrderone =(id) => {
        getMethodData( `/admin/orders/details/${id}`, this.Token)
        .then((resp) => {
            runInAction(() => {
                this.loadOrder = true;
                this.errloadOrder = "";
                });
            if (resp?.data?.status === false) {
            runInAction(() => {
                this.loadOrder = false;
                this.errloadOrder = resp.data.message;
                // toast.error(resp.data.message);
            });
            return;
            }
            if (resp?.data?.status === true) {
            runInAction(() => {
                this.loadOrder = false;
                this.orderone = resp?.data?.data
            })
            return;
            }
            runInAction(() => {
            this.loadOrder = false;
            this.errloadOrder = resp.data.message;
            toast.error(resp.data.message);
            });
        })
        .catch((error) => {
            runInAction(() => {
                if(!error.response){
                this.loadOrder = false;
                this.errloadOrder = error?.message;
                toast.error(error?.message);
                }
                toast.error(error?.response?.data?.message);
                this.loadOrder = false;
                this.errloadOrder = error?.response?.data?.message;
            })
            return;
        })
    }
    getProductList =(id) => {
        getMethodData( `/admin/orders/get-order-products/${id}`, this.Token)
        .then((resp) => {
            runInAction(() => {
                this.loadOrder = true;
                this.errloadOrder = "";
                });
            if (resp?.data?.status === false) {
            runInAction(() => {
                this.loadOrder = false;
                this.errloadOrder = resp.data.message;
                // toast.error(resp.data.message);
            });
            return;
            }
            if (resp?.data?.status === true) {
            runInAction(() => {
                this.loadOrder = false;
                this.productlist = resp?.data?.data
            })
            return;
            }
            runInAction(() => {
            this.loadOrder = false;
            this.errloadOrder = resp.data.message;
            toast.error(resp.data.message);
            });
        })
        .catch((error) => {
            runInAction(() => {
                if(!error.response){
                this.loadOrder = false;
                this.errloadOrder = error?.message;
                toast.error(error?.message);
                }
                toast.error(error?.response?.data?.message);
                this.loadOrder = false;
                this.errloadOrder = error?.response?.data?.message;
            })
            return;
        })
    }



        //filter Order api 

        getFilterOrders = (page) => {
            runInAction(() => {
                this.loadOrder = true;
                this.errloadOrder = "";
                this.saved = false;
                this.activePage = page;
                this.indexPage = page;
                this.filtertrue=false;
                this.Orders=[]
                if(!this.nofilterwithpagination){
                  this.previousValues = {
                    order_number: this.orderid,
                    customer: this.customername,
                    email: this.customeremail ,
                    from_date: this.fromdate,
                    to_date: this.todate,
                    payment_status: this.status,
                    product_type: this.ProductType,
                    product_name: this.ProductName,
                    payment_method: this.paymentmethod 
                  };
                }
              });
              if(this.nofilterwithpagination){
                getMethodData(`/admin/orders?page=${page}&order_number=${this.previousValues?.order_number}&customer=${this.previousValues?.customer}&email=${this.previousValues?.email}&from_date=${this.previousValues?.from_date}&to_date=${this.previousValues?.to_date}&payment_status=${this.previousValues?.payment_status}&product_type=${this.previousValues?.product_type}&product_name=${this.previousValues?.product_name}}&payment_method=${this.previousValues?.payment_method}`,this.Token)
                .then((resp) => {
          
                    if (resp?.data?.status === false) {
                      runInAction(() => {
                        this.loadOrder = false;
                        this.errloadOrder = resp?.data?.message
                      });
                      return;
                    }
                    if (resp?.data?.status === true) {
                        runInAction(() => {
                            this.loadOrder = false;
                            this.errloadOrder = resp?.data?.message;
                            // console.log(resp?.data?.data.length);
                            if(resp?.data?.data?.data?.length === 0) {
                                this.nolist = true;
                            }else{
                                this.nolist = false;
                            }
                            // console.log(resp?.data?.data?.data)
                            this.filtertrue = true;
                            this.Orders = resp?.data?.data?.data;
                            this.Count = resp.data.data.total;
                            this.perPage = resp.data.data.per_page;
                            this.filterVisibility = true;
                            this.saved = false;
                            this.activePage = resp?.data?.data?.current_page;
                            // this.status = true;
                            });
                      return;
                    }
                    runInAction(() => {
                      this.loadOrder = false;
                      this.errloadOrder = resp?.data?.message;
                    });
                  })
                  return;
              }
              
            getMethodData(`/admin/orders?page=${page}&order_number=${this.orderid}&customer=${this.customername}&email=${this.customeremail}&from_date=${this.fromdate}&to_date=${this.todate}&payment_status=${this.status}&product_type=${this.ProductType}&product_name=${this.ProductName}&payment_method=${this.paymentmethod}`,this.Token)
            .then((resp) => {
      
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadOrder = false;
                    this.errloadOrder = resp?.data?.message
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.loadOrder = false;
                        this.errloadOrder = resp?.data?.message;
                        // console.log(resp?.data?.data.length);
                        if(resp?.data?.data?.data?.length === 0) {
                            this.nolist = true;
                        }else{
                            this.nolist = false;
                        }
                        // console.log(resp?.data?.data?.data)
                            this.filtertrue = true;
                            this.Orders = resp?.data?.data?.data;
                            this.Count = resp.data.data.total;
                            this.perPage = resp.data.data.per_page;
                            this.filterVisibility = true;
                            this.activePage = resp?.data?.data?.current_page;
                        this.saved = false;
                        // this.status = true;
                        });
                  return;
                }
                runInAction(() => {
                  this.loadOrder = false;
                  this.errloadOrder = resp?.data?.message;
                });
              })
              .catch((error) => {
                runInAction(() => {
                    this.loadOrder = false;
                    if(!error.response){
                        this.errloadOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
        };
};


export const OrderStoreContext = createContext(new OrderStore());
export const useOrderStore = () => useContext(OrderStoreContext);