import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData, postFormData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
import { format } from 'date-fns';


export class CouponStore {

  Token = null;
  loadcouponlist = false;
  name="";
  code="";
  saved=false;
  startdate="";
  enddate="";
  discount="";
  discount_type="";
  status="";
  coupon = [];
  errloadcoupon = "";
  nolist = false;
  loadCoupon = false;
  coupon_id = ""
  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.isloading) {
      this.getCoupon();
      return
    }
    if (isloading.editloading) {
      this.getcouponbyid(isloading.id);
      return
    }
  }

  setToken=(e)=>{
    this.Token=e;
  }
  //   // get Coupen id
  getcouponid = (text) => {
    this.coupon_id = text;
  }

  //   // get home name
  //   getHomename = (text) => {
  //     this.homename = text;
  //   }

  // get status 
  getstatus = (text) => {
    this.status = text;
  };
// get updated status 
getupdatedstatus =(text)=>{
  this.updatedStatus = text;
}
//get year
 getyear =(text)=>{
this.year=text;
 }
  //get name
  getname = (text) => {
    this.name = text;
  };
//get code 
getcode=(text)=>{
  this.code=text;
}
//get discount
getdiscount=(text)=>{
  this.discount=text;
}
getdiscount_type=(text)=>{
 this.discount_type=text;
}
  //get start date
  getstartdate = (text) => {
    this.startdate = text;
  };
  //get end date
  getenddate = (text) => {
    this.enddate = text;
  };
//edit start date
editstartdate =(text)=>{
this.updatedStartDate=text
}
//edit end date
editenddate =(text)=>{
  this.updatedEndDate=text
  }

//edit season name 
editseasonname =(text)=>{
  this.editname = text
}
//edit year 
edityears=(text)=>{
  this.edityear=text;
}
editseasonstatus =(text)=>{
this.editstatus =text
}


  //   //get zipcode
  //   getzipcode = (text) => {
  //     this.zipcode = text;
  //   };

  //   // get country name
  //   getcountryname = (text) => {
  //     this.country = text;
  //   };

  // get Coupen list
  getCoupon = () => {
    runInAction(() => {
      this.loadCoupon = true;
      this.errloadcoupon = "";
      this.saved = false;
    });
    getMethodData("/admin/coupon-codes", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message;
            if (resp?.data?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.coupon = resp?.data?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadCoupon = false;
          this.errloadcoupon = resp?.data?.message
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCoupon = false;
          if (!error.response) {
            this.errloadcoupon = error?.message;
            toast.error(error?.message);
          }
          this.errloadcoupon = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };

  // get filter Coupen
  getFilterCoupen = (page) => {
    runInAction(() => {
      this.loadCoupon = true;
      this.errloadcoupon = "";
      this.saved = false;
    });
    getMethodData(`/admin/coupon-codes?page=${page}&coupon_name=${this.name}&discount_type=${this.discount_type}&valid_from=${this.startdate}&valid_to=${this.enddate}&status=${this.status}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message;
            if (resp?.data?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.coupon = resp?.data?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadCoupon = false;
          this.errloadcoupon = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCoupon = false;
          if (!error.response) {
            this.errloadcoupon = error?.message;
            toast.error(error?.message);
          }
          this.errloadcoupon = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };

  // add Season
  addCoupon = () => {
    runInAction(() => {
      this.loadCoupon = true;
      this.errloadcoupon = "";
    });
    const formData = new FormData();
    formData.append("coupon_name", this.name);
    formData.append("discount_type", this.discount_type);
    formData.append("discount", parseInt(this.discount));
    formData.append("valid_from", this.startdate);
    formData.append("valid_to", this.enddate);
    formData.append("status", this.status);
    postFormData("/admin/coupon-codes/store",formData, this.Token).then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {

          this.loadCoupon = false;
          this.errloadcoupon = resp?.data?.message;
          toast.error(resp.data?.result?.errors?.coupon_name[0]);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.loadCoupon = false;
          this.errloadcoupon = resp?.data?.message;
          toast.success(resp.data.message);
          this.getCoupon();
          this.saved = true;
        })
        return
      }
   
      runInAction(() => {
        this.loadCoupon = false;

        this.errloadcoupon = resp?.data?.message;
      });
    })
      .catch((error) => {
        runInAction(() => {
            this.loadCoupon = false;
          if (!error.response) {
        

            this.errloadcoupon = error?.message;
            toast.error(error?.message);
          }
          this.errloadcoupon = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };



  //Active and inactive
  statusChange = () => {
    runInAction(() => {
        this.loadCoupon = false;
        this.errloadcoupon = "";
    });
    getMethodData(`/admin/coupon-codes/change-status/${this.coupon_id}`,this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message;
            this.getCoupon();
            toast.success(resp?.data?.message);
          })
          return;
        }
        runInAction(() => {
          this.loadCoupon = false;
          this.errloadcoupon = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loadCoupon = false;
            this.errloadcoupon = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadCoupon = false;
          this.errloadcoupon = error?.response?.data?.message;
        })
        return;
      })
  };

  //get Coupon by id
  getcouponbyid = (id) => {
    getMethodData(`/admin/coupon-codes/edit/${id}`, this.Token)
      .then((resp) => {
        runInAction(() => {
          this.loadCoupon = true;
          this.errloadcoupon = "";
        });
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp.data.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
          this.loadCoupon = false;
          this.errloadcoupon = resp?.data?.message;
          this.startdate = format(new Date(resp?.data?.data?.valid_from), 'yyyy-MM-dd');
          this.discount = resp?.data?.data?.discount;
          // // this.code = resp?.data?.data?.code;
          this.discount_type = resp?.data?.data?.discount_type;
          this.enddate = format(new Date(resp?.data?.data?.valid_to), 'yyyy-MM-dd');
          this.name = resp?.data?.data?.coupon_name;
          this.status = resp?.data?.data?.status;
          })
          return;
        }
        runInAction(() => {
          this.loadCoupon = false;
          this.errloadcoupon = resp.data.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loadCoupon = false;
            this.errloadcoupon = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadCoupon = false;
          this.errloadcoupon = error?.response?.data?.message;
        })
        return;
      })
  }


  //update method
  updatedcoupon = (id) => {
    runInAction(() => {
      this.loadCoupon = true;
      this.errloadcoupon = "";
    });
    const formData = new FormData();
    formData.append("coupon_name", this.name);
    formData.append("discount_type", this.discount_type);
    formData.append("discount", parseInt(this.discount));
    formData.append("valid_from", this.startdate);
    formData.append("valid_to", this.enddate);
    formData.append("status", this.status);
    postFormData(`/admin/coupon-codes/update/${id}`,
      formData,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCoupon = false;
            this.errloadcoupon = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.errloadcoupon = false;
              this.errloadcoupon = resp?.data?.message;
              toast.success(resp.data.message);
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.loadCoupon = false;
          this.errloadcoupon = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.loadCoupon = false;
              this.errloadcoupon = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.loadCoupon = false;
              this.errloadcoupon = error?.response?.data?.message;
        })
        return;
    })
  };
}




export const CouponStoreContext = createContext(new CouponStore());
export const useCouponStore = () => useContext(CouponStoreContext);