import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Input, Button } from "react-daisyui"
import { useReviewStore, ReviewStore, ReviewStoreContext } from "../store/ReviewStore";
import { observer } from "mobx-react";
import { useProductStore } from "../../ProductManagement/store/ProductStore";
import { useAuthStore } from "../../Auth/store/Auth";

// const Filter = observer(() => {
//     const store = new ReviewStore({
//         isloading:true,
//       });
//     return (
//         <ReviewStoreContext.Provider value={store}>
//             <Screen />
//         </ReviewStoreContext.Provider>
//     )
// })

// export default Filter;

const Filter = observer(() => {
    const [searchParams] = useSearchParams();
    const searchParamPage = searchParams.get("page");
    const {Token} = useAuthStore();

    const {
        Categorylist,
        getCategoryList,
      } = useProductStore();

    const {
        getReviews,
        getFilterReviews,
        setorderid,
        Productlist,
        setcategory,
        setproduct,
        setfromdate,
        settodate,
        setcustomeremail,
        setcustomername,
        setToken
    }
    = useReviewStore()

    const Reset=()=>{
        setorderid("")
        setcategory("")
        setproduct("")
        setfromdate("")
        settodate("")
        setcustomeremail("")
        setcustomername("")
        getReviews(searchParamPage || 1);
    }

    useEffect(() => {
        setToken(Token)
        getCategoryList()
    },[])
    return (
        <>

            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form onSubmit={(e) => {getFilterReviews(searchParamPage || 1);e.preventDefault() }}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label htmlFor="orderId" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Order ID</label>
                            <Input
                                name="orderId"
                                placeholder="Order ID"
                                id="orderId"
                                onChange={(e)=> {setorderid(e.target.value)}}
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="ProductName" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Product Name</label>
                            <select
                                id="ProductName"
                                name="ProductName"
                                defaultValue=""
                                onChange={(e)=> {setproduct(e.target.value)}}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" className="">All</option>
                                {Productlist && Productlist.map((res,index)=> (
                                    <option key={index} value={res?.id}>{res?.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="Category" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Product Category</label>
                            <select
                                id="Category"
                                name="Category"
                                defaultValue=""
                                onChange={(e)=> {setcategory(e.target.value)}}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" className="">All</option>
                                {Categorylist && Categorylist.map((res,index)=> (
                                    <option key={index} value={res?.id}>{res?.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="fromDate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">From Date</label>
                            <Input
                                name="fromDate"
                                placeholder=" From Date"
                                id="fromDate"
                                onChange={(e)=> {setfromdate(e.target.value)}}
                                type="date"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="toDate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">To Date</label>
                            <Input
                                name="toDate"
                                placeholder=" To Date"
                                id="toDate"
                                onChange={(e)=> {settodate(e.target.value)}}
                                type="date"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="CustomerName" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Customer Name</label>
                            <Input
                                name="CustomerName"
                                placeholder="Customer Name"
                                id="CustomerName"
                                onChange={(e)=> {setcustomername(e.target.value)}}
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="CustomerEmail" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Customer Email</label>
                            <Input
                                name="CustomerEmail"
                                placeholder="Customer Email"
                                id="CustomerEmail"
                                onChange={(e)=> {setcustomeremail(e.target.value)}}
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        {/* <div>
                            <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status</label>
                            <select
                                id="Status"
                                name="Status"
                                defaultValue=""
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" className="">All</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive </option>
                            </select>
                        </div> */}
                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <button type="reset" onClick={() => { Reset()  }} className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineRefresh className="mr-1" />Reset</button>
                        <button type="submit" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"><MdOutlineSearch className="mr-1" />Search</button>
                    </div>
                </form>
            </div>

        </>
    )
})
export default Filter;