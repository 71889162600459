import {createContext,useContext} from 'react'
import { makeAutoObservable, runInAction } from "mobx";
import {getMethodData,putMethod, patchMethodData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class MyearningStore {
    Token = null;
    indexPage=1;
    filtertrue=false;
    activePage=1;
    Count=1;
    perPage="";
    filterVisibility=true;
    loadPayments= false;
    errloadPayments = "";
    saved= false;
    nolist=false;
    Payments=[];
    orderone={};
    nofilterwithpagination= false;
    transactionid="";
    orderid="";
    fromdate="";
    todate="";
    customername="";
    customeremail="";
    status="";
    transactiontype="";
    previousValues = {
      transaction_id: "",
      order_number: "",
      email: "" ,
      from_date: "",
      to_date: "",
      transacton_status: "",
      customer:"",
      transaction_type:"",
    };
    

    constructor(isloading = false) {
        makeAutoObservable(this);
        this.Token = localStorage.getItem("Admintoken");
        if(isloading.isloading){
            this.getPayments(isloading.pagginationcount);
            return
        }
        if(isloading.editloading){
            this.getOrderone(isloading?.id)
        }
    }


    settransactionid = (text) =>  {
        this.transactionid = text;
    }
    setorderid = (text) =>  {
        this.orderid = text;
    }
    setfromdate = (text) =>  {
        this.fromdate = text;
    }
    settodate = (text) =>  {
        this.todate = text;
    }
    setcustomername = (text) =>  {
        this.customername = text;
    }
    setcustomeremail = (text) =>  {
        this.customeremail = text;
    }
    setstatus = (text) =>  {
        this.status = text;
    }

    settransactiontype = (text) =>  {
      this.transactiontype = text;
  }

    setActivePage = (text) =>{
        this.activePage = text;
      }
      setIndexPage=(e)=>{
        this.indexPage=e;
      }
      setCount=(e)=>{
        this.Count=e;
      }
      setPerPage=(e)=>{
        this.perPage=e;
      }
      setFilterVisibility=(e)=>{
        this.filterVisibility=e;
      } 
      
      setfiltertrue=(e)=>{
        this.filtertrue = e;
      }
      setToken=(e)=>{
        this.Token=e;
      }
      NofilterwithPagination = (e) => {
        runInAction(() => {
            this.nofilterwithpagination = e
        })
      }

      loadTableData = async (pageNumber) => {
        runInAction(() => {
          this.loadPayments = true;
          this.errloadPayments = "";
          this.Payments =[]
          this.saved = false;
          this.setIndexPage(1);
  
          });
  
    
        try {
          const response = await getMethodData(`/admin/transactons?page=${pageNumber === 1 ? 1 : pageNumber}`,this.Token)
          if (response?.data?.status === false) {
            runInAction(() => {
            this.loadPayments = false;
            this.errloadPayments = response?.data?.message
            });
            return;
        }
        if (response?.data?.status === true) {
          runInAction(() => {
            this.loadPayments = false;
            this.errloadPayments = response?.data?.message;
            // console.log(resp?.data?.data.length);
            if(response?.data?.data?.data?.length === 0) {
                this.nolist = true;
            }else{
                this.nolist = false;
            }
              this.setCount(response.data.data.total);
              this.setPerPage(response.data.data.per_page);
              this.setActivePage(response.data.data.current_page)
              this.Payments = response?.data?.data?.data;
              this.filterVisibility = true;
  });
        }
  
      
    
        } catch (error) {
          runInAction(() => {
            this.loadPayments = false;
            console.error("Error loading table data:", error);
        });
        }
      };
      
    // get order List
    getPayments = (page) => {
        runInAction(() => {
            this.loadPayments = true;
            this.errloadPayments = "";
            this.saved = false;
            this.indexPage = page;
            });
        getMethodData(`/admin/transactons?page=${page}`,this.Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                this.loadPayments = false;
                this.errloadPayments = resp?.data?.message
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                this.loadPayments = false;
                this.errloadPayments = resp?.data?.message;
                // console.log(resp?.data?.data.length);
                if(resp?.data?.data?.data?.length === 0) {
                    this.nolist = true;
                }else{
                    this.nolist = false;
                }
                // console.log(resp?.data?.data?.data)
                this.filtertrue = true;
                this.Payments = resp?.data?.data?.data;
                this.Count = resp.data.data.total;
                this.perPage = resp.data.data.per_page;
                this.activePage = resp?.data?.data?.current_page;
                this.filterVisibility = true;
                this.saved = false;
                // this.status = true;
                });
                return;
            }
            runInAction(() => {
                this.loadPayments = false;
                this.errloadPayments = resp?.data?.message;
            });
            })
            .catch((error) => {
            runInAction(() => {
                this.loadPayments = false;
                if(!error.response){
                    this.errloadPayments = error?.message;
                    toast.error(error?.message);
                }
                this.errloadPayments = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };





   



        //filter Order api 

        getFilterMyearning = (page) => {
            runInAction(() => {
                this.loadPayments = true;
                this.errloadPayments = "";
                this.activePage = page;
                this.indexPage = page;
                this.saved = false;
                if(!this.nofilterwithpagination){
                  this.previousValues = {
                    transaction_id: this.transactionid,
                    order_number: this.orderid,
                    email: this.customeremail ,
                    from_date: this.fromdate,
                    to_date: this.todate,
                    transacton_status: this.status,
                    customer: this.customername,
                    transaction_type:this.transactiontype
                  };
                }
              });
              if(this.nofilterwithpagination){
                getMethodData(`/admin/transactons?page=${page}&transaction_id=${this.previousValues.transaction_id}&order_number=${this.previousValues?.order_number}&customer=${this.previousValues?.customer}&email=${this.previousValues?.email}&from_date=${this.previousValues?.from_date}&to_date=${this.previousValues?.to_date}&transacton_status=${this.previousValues?.transacton_status}&transaction_type=${this.previousValues?.transaction_type}`,this.Token)
                .then((resp) => {
          
                    if (resp?.data?.status === false) {
                      runInAction(() => {
                        this.loadPayments = false;
                        this.errloadPayments = resp?.data?.message
                      });
                      return;
                    }
                    if (resp?.data?.status === true) {
                        runInAction(() => {
                            this.loadPayments = false;
                            this.errloadPayments = resp?.data?.message;
                            // console.log(resp?.data?.data.length);
                            if(resp?.data?.data?.data?.length === 0) {
                                this.nolist = true;
                            }else{
                                this.nolist = false;
                            }
                            // console.log(resp?.data?.data?.data)
                            this.filtertrue = true;
                            this.Payments = resp?.data?.data?.data;
                            this.Count = resp.data.data.total;
                            this.perPage = resp.data.data.per_page;
                            this.filterVisibility = true;
                            this.activePage = resp?.data?.data?.current_page;
                            this.saved = false;
                            // this.status = true;
                            });
                      return;
                    }
                    runInAction(() => {
                      this.loadPayments = false;
                      this.errloadPayments = resp?.data?.message;
                    });
                  })
                  return;
              }

            getMethodData(`/admin/transactons?page=${page}&transaction_id=${this.transactionid}&order_number=${this.orderid}&customer=${this.customername}&email=${this.customeremail}&from_date=${this.fromdate}&to_date=${this.todate}&transacton_status=${this.status}&transaction_type=${this.transactiontype}`,this.Token)
            .then((resp) => {
      
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadPayments = false;
                    this.errloadPayments = resp?.data?.message
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.loadPayments = false;
                        this.errloadPayments = resp?.data?.message;
                        // console.log(resp?.data?.data.length);
                        if(resp?.data?.data?.data?.length === 0) {
                            this.nolist = true;
                        }else{
                            this.nolist = false;
                        }
                        // console.log(resp?.data?.data?.data)
                            this.filtertrue = true;
                            this.Payments = resp?.data?.data?.data;
                            this.Count = resp.data.data.total;
                            this.perPage = resp.data.data.per_page;
                            this.filterVisibility = true;
                            this.activePage = resp?.data?.data?.current_page;
                        this.saved = false;
                        // this.status = true;
                        });
                  return;
                }
                runInAction(() => {
                  this.loadPayments = false;
                  this.errloadPayments = resp?.data?.message;
                });
              })
              .catch((error) => {
                runInAction(() => {
                    this.loadPayments = false;
                    if(!error.response){
                        this.errloadPayments = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadPayments = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
        };
};


export const MyearningStoreContext = createContext(new MyearningStore());
export const useMyearningStore = () => useContext(MyearningStoreContext);