import { createContext, useContext } from "react";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData, postFormData } from "../../ApiMethods/api";
import { toast } from "react-toastify";


export class CmsStore {

  Token = null;
  loadCmslist = false;
  title="";
  meta_keyword="";
  meta_description="";
  content="";
  saved=false;
  startdate="";
  enddate="";
  discount="";
  discount_type="";
  status="";
  cms = [];
  errloadCms = "";
  nolist = false;
  loadCms = false;
  cms_id = "";
  

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.isloading) {
      this.getCms();
      return
    }
    if (isloading.editloading) {
      this.getcmsbyid(isloading.id);
      return
    }
  }
  getbytitle=(text)=>{
    this.title = text;
  }
  getcontent=(text)=>{
    this.content = text;
  }
  getcmsid=(text)=>{
    this.cms_id = text;
  }
  getstatus = (text) => {
      this.status = text;
  };
  setToken=(e)=>{
    this.Token=e;
  }

  // get Coupen list
  getCms = () => {
    runInAction(() => {
      this.loadCms = true;
      this.errloadCms = "";
      this.saved = false;
    });
    getMethodData("/admin/cms-pages", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCms = false;
            this.errloadCms = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCms = false;
            this.errloadCms = resp?.data?.message;
            if (resp?.data?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.cms = resp?.data?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadCms = false;
          this.errloadCms = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCms = false;
          if (!error.response) {
            this.errloadCms = error?.message;
            toast.error(error?.message);
          }
          this.errloadCms = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };

  //Active and inactive
  statusChange = () => {
    runInAction(() => {
      this.loadSeason = true;
      this.errloadseason = "";
    });
    getMethodData(`/admin/cms-pages/change-status/${this.cms_id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCms = false;
            this.errloadCms = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCms = false;
            this.errloadCms = resp?.data?.message;
            this.getCms();
            toast.success(resp?.data?.message);
          })
          return;
        }
        runInAction(() => {
          this.loadCms = false;
          this.errloadCms = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loadCms = false;
            this.errloadCms = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadCms = false;
          this.errloadCms = error?.response?.data?.message;
        })
        return;
      })
  };

//   //get Cms by id
  getcmsbyid = (id) => {
    runInAction(() => {
      this.loadCms = true;
      this.errloadCms = "";
    });

    getMethodData(`/admin/cms-pages/edit/${id}`, this.Token)
      .then((resp) => {
       
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCms = false;
            this.errloadCms = resp.data.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
          this.loadCms = false;
          this.errloadCms = resp?.data?.message;
          this.title = resp?.data?.data?.title;
          this.meta_keyword = resp?.data?.data?.meta_keyword;
          this.meta_description = resp?.data?.data?.meta_description;
          this.content = resp?.data?.data?.content;
          this.status = resp?.data?.data?.status;
          })
          return;
        }
        runInAction(() => {
          this.loadCms = false;
          this.errloadCms = resp.data.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loadCms = false;
            this.errloadCms = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loadCms = false;
          this.errloadCms = error?.response?.data?.message;
        })
        return;
      })
  }


//   //update method
  updatedcms = (id) => {
    runInAction(() => {
      this.loadCms = true;
      this.errloadCms = "";
    });
    const formData = new FormData();
    formData.append("title", this.title);
    formData.append("meta_keyword", this.meta_keyword);
    formData.append("meta_description", this.meta_description);
    formData.append("content", this.content);
    formData.append("status", this.status);
    postFormData(`/admin/cms-pages/update/${id}`,
      formData,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCms = false;
            this.errloadCms = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.errloadCms = false;
              this.erraddcity = resp?.data?.message;
              toast.success(resp.data.message);
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.loadCms = false;
          this.errloadCms = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.loadCms = false;
              this.errloadCms = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.loadCms = false;
              this.errloadCms = error?.response?.data?.message;
        })
        return;
    })
  };
}




export const CmsStoreContext = createContext(new CmsStore());
export const useCmsStore = () => useContext(CmsStoreContext);