import { createContext, useContext } from "react";
import {makeAutoObservable,observable,runInAction} from "mobx";
import { postMethodData, getMethodData, putMethod , delMethod, patchMethodData, postFormData } from "../../ApiMethods/api";
import { toast } from "react-toastify";




export class ProfileStore {

  Token = null;
  loadcitylist = false;

  city = [];
  errloadprofile = "";
  loadprofile = false;
  nolist = false;

  addloadprofile = false;
  erraddprofile = "";
  cityname = "";
  email = "";
  lastname  = "";
  status = true;

  // upadateprofile = {};
  cityid = ""


  updatedzipcode="";

  firstname= "";
  statuspopup = false;


  saved = false

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if(isloading.isloading){
      // this.getCity();
      return
    }
    if(isloading.editloading){
      this.getprofileone(isloading.id);
      return
    }
  }
  setToken=(e)=>{
    this.Token=e;
  }
  // status popup
  setstatuspopup = () => {
    this.statuspopup = !this.statuspopup;
  };

  // get city id
  getid = (text) => {
    this.cityid = text;
  }

  // get status 
  getstatus = (text) => {
    this.status = text;
  };

  //get city name
  getcityname = (text) => {
    this.cityname = text;
  };

  //get updated city
  updatedfirstname = (text) => {
    this.firstname = text;
  };

  //get email
  getemail = (text) => {
    this.email = text;
  };

  // get lastname name
  getlastname = (text) => {
    this.lastname = text;
  };


  //get profile 
  getprofileone =() => {
    getMethodData( `/admin/get-profile`, this.Token)
    .then((resp) => {
        runInAction(() => {
            this.loadprofile = true;
            this.errloadprofile = "";
          });
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loadprofile = false;
          this.errloadprofile = resp.data.message;
          toast.error(resp.data.message);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.loadprofile = false;
          this.errloadprofile = resp?.data?.message;
          // this.upadateprofile= resp.data.data;
          this.firstname =resp?.data?.data?.first_name;
          this.lastname = resp?.data?.data?.last_name;
          this.email = resp?.data?.data?.email;
          // this.status = resp?.data?.data?.isActive;
        })
        return;
      }
      runInAction(() => {
        this.loadprofile = false;
        this.errloadprofile = resp.data.message;
        toast.error(resp.data.message);
      });
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
            this.loadprofile = false;
            this.errloadprofile = error?.message;
            toast.error(error?.message);
          }
            toast.error(error?.response?.data?.message);
            this.loadprofile = false;
            this.errloadprofile = error?.response?.data?.message;
      })
      return;
  })
}


//update method
updateprofile = () => {
  const formData = new FormData();
  // Object.keys(data).forEach((key) => {
  //   if(key === "image"){
  //     if (data.image && data.image.length > 0) {
  //       formData.append("image", data.image[0]);
  //     } else {
  //       formData.delete("image");
  //     }
  //   }
  //   else {
  //     if (!appendedKeys.includes(key)) {
  //       formData.append(key, data[key]);
  //       appendedKeys.push(key); // add to the appended keys list
  //     }
  //   }
  // });
  formData.append("first_name",this.firstname)
  formData.append("last_name",this.lastname)
    runInAction(() => {
      this.addloadprofile = true;
      this.erraddprofile = "";
    });
    postFormData("/admin/update-profile",
      formData,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addloadprofile = false;
            this.erraddprofile = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.addloadprofile = false;
              this.erraddprofile = resp?.data?.message;
              toast.success(resp.data.message);
              // this.getCity();
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.addloadprofile = false;
          this.erraddprofile = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.addloadprofile = false;
              this.erraddprofile = error?.message;
              toast.error(error?.response?.data?.errors[0]);
            }
              toast.error(error?.response?.data?.errors[0]);
              this.addloadprofile = false;
              this.erraddprofile = error?.response?.data?.message;
        })
        return;
    })
  };


}




export const ProfileStoreContext = createContext(new ProfileStore());
export const useProfileStore = () => useContext(ProfileStoreContext);
